<template>
    <div>
        <v-form v-model="isValid" @submit.prevent="">
            <v-row>
                <v-col>
                    <h3 class="font-weight-bold">{{config.question}}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h4>Upload a photo of the front of your card</h4>
                    <v-file-input accept=".jpg, .png, .jpeg, .bmp" :rules="fileRules" label="Select front photo" show-size
                                  v-model="fileFront"></v-file-input>
                    <v-btn block v-if="config.showBackOption === true && hasBack === false" v-on:click.stop="hasBack = true">
                        My card has a back
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="hasBack === true">
                <v-col>
                    <h4>Upload a photo of the back of your card</h4>
                    <v-file-input accept="image/*" :rules="fileRules" label="Select back photo" show-size
                                  v-model="fileBack"></v-file-input>
                </v-col>
            </v-row>
            <v-row v-if="hasError === true">
                <v-col>
                    <h4 class="red--text">{{errorMessage}}</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-right" order="last">
                    <v-btn color="primary" v-on:click.stop="clickContinue()" :disabled="!isValid || isLoading"
                           :loading="isLoading">
                        Continue
                        <template v-slot:loader>
                            {{uploadProgress}}%
                        </template>
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col order="first">
                    <v-btn v-if="config.clickBack !== null" v-on:click.stop="config.clickBack()">
                        Back
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    export default {
        name: 'photos',
        data: function () {
            return {
                fileRules: [
                    v => !!v || 'Photo is required',
                    v => !v || v.size <= (7 * 1024 * 1024) || 'Photo size larger than 7MB',
                    v => !v || v.size > 0 || 'Photo size 0MB',
                    v => !v || ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'].indexOf(v.type) !== -1 || 'Photo type unsupported - Accepts PNG, JPG, JPEG, BMP'
                ],
                fileFront: undefined,
                fileBack: undefined,
                isValid: false,
                hasError: false,
                errorMessage: 'An error occurred. Please try again.',
                hasBack: false,
                isLoading: false,
                uploadProgress: 0
            }
        },
        props: ['config'],
        mounted() {
            window.scrollTo(0, 0)
            // removes and replaces component on mount
            const inputs = document.querySelectorAll('.v-file-input input');
            [...inputs].forEach((input) => {
              input.remove()
            })
        },
        methods: {
            clickContinue: async function () {
                if (this.isLoading === false) {
                    try {
                        // Upload Photos
                        this.isLoading = true
                        this.hasError = false

                        let base64Images = {};

                        if (typeof this.fileFront !== 'undefined') {
                          base64Images['front'] = {
                            name:this.fileFront.name,
                            type:this.fileFront.type,
                            base64: await this.readFile(this.fileFront)
                          }
                        }
                        if (typeof this.fileBack !== 'undefined') {
                          base64Images['back'] = {
                            name:this.fileBack.name,
                            type:this.fileBack.type,
                            base64: await this.readFile(this.fileBack)
                          }
                        }
                        let response = undefined;
                        try {
                            response = await this.$http.post('/uploadCard', base64Images, {
                                onUploadProgress: function (progressEvent) {
                                    if (progressEvent.loaded && progressEvent.total) {
                                        this.uploadProgress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                                    }
                                }.bind(this),
                                timeout: 30000 // 30 seconds timeout
                            })
                        } catch (e) {
                            if (e.code === 'ECONNABORTED') {
                                this.errorMessage = 'Upload timed out. Please try again.'
                            }
                            throw e
                        }

                        // Store result
                        let payload = {}
                        if (response && response.data) {
                            if (response.data.front) {
                                payload[`${this.config.storeField}_front`] = response.data.front
                            }
                            if (response.data.back) {
                                payload[`${this.config.storeField}_back`] = response.data.back
                            }
                        }
                        await this.$http.post('/save', {current:this.$store.getters['registration/get'],payload:payload})

                        this.$store.commit('registration/update', payload)

                        // Execute callback click
                        await this.config.clickContinue()

                    } catch (e) {
                        this.isLoading = false
                        this.hasError = true
                        this.fileFront = undefined
                        this.fileBack = undefined
                    }
                }
            },
            // Read image file and convert to base64
            readFile: async function(file) {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = res => {
                  resolve(res.target.result);
                };
                reader.onerror = err => reject(err);
                reader.readAsDataURL(file)
              });
            }
        }
    }
</script>
