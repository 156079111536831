import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

// Registration Flow
import Registration from '../components/Registration'

import RegName from '../components/registration/Name'
import RegPhone from '../components/registration/Phone'
import RegVerifyPhone from '../components/registration/VerifyPhone'
import RegDateOfBirth from '../components/registration/DateOfBirth'
import RegEmergencyContact from '../components/registration/EmergencyContact'
import RegGuardianContact from '../components/registration/GuardianContact'
import RegMaritalStatus from '../components/registration/MaritalStatus'
import RegEmployer from '../components/registration/Employer'
import RegHomeAddress from '../components/registration/HomeAddress'
import RegMedicalRecordsRelease from '../components/registration/MedicalRecordsRelease'
import RegMedicalRecordsReleaseStep1 from '../components/registration/MedicalRecordsReleaseStep1'
import RegMedicalRecordsReleaseStep2 from '../components/registration/MedicalRecordsReleaseStep2'
import RegMedicalRecordsReleaseStep3 from '../components/registration/MedicalRecordsReleaseStep3'
import RegMedicalRecordsReleaseStep4 from '../components/registration/MedicalRecordsReleaseStep4'
import RegMedicalRecordsReleaseStep5 from '../components/registration/MedicalRecordsReleaseStep5'
import RegEmail from '../components/registration/Email'
import RegGender from '../components/registration/Gender'
import RegRace from '../components/registration/Race'
import RegEthnicity from '../components/registration/Ethnicity'
import RegEmergencyRoom from '../components/registration/EmergencyRoom'
import RegBackground from '../components/registration/Background'
import SsBackground from '../components/registration/SsBackground.vue'
import BhBackground from '../components/registration/BhBackground'
import BhBackgroundGad from '../components/registration/BhBackgroundGad'
import BhBackgroundPhq from '../components/registration/BhBackgroundPhq'
import BhBackgroundSuicideSeverityStep1 from '../components/registration/BhBackgroundSuicideSeverityStep1.vue'
import BhBackgroundSuicideSeverityStep2 from '../components/registration/BhBackgroundSuicideSeverityStep2.vue'
import RegConditions from '../components/registration/Conditions'
import RegPrograms from '../components/registration/Programs'
import RegColonCancerScreening from '../components/registration/ColonCancerScreening'
import RegMammogram from '../components/registration/Mammogram'
import RegTrt from '../components/registration/Trt'
import RegHasInsurance from '../components/registration/HasInsurance'
import RegInsurance from '../components/registration/Insurance'

import RegConsentElectronicSignature from '../components/registration/ConsentElectronicSignature'
import RegConsentHipaa from '../components/registration/ConsentHipaa'
import RegConsentSpecimenCollection from '../components/registration/ConsentSpecimenCollection'
import RegConsentAssignmentOfBenefits from '../components/registration/ConsentAssignmentOfBenefits'
import RegConsentFinancialPolicy from '../components/registration/ConsentFinancialPolicy'
import RegConsentPatientTreatmentContract from '../components/registration/ConsentPatientTreatmentContract'
import RegConsentPrivacyPractices from '../components/registration/ConsentPrivacyPractices'
import RegMedicareNumber from '../components/registration/MedicareNumber'

import RegInsurancePhotos from '../components/registration/InsurancePhotos'
import RegIdentificationPhotos from '../components/registration/IdentificationPhotos'

import RegSummary from '../components/registration/Summary'
import RegComplete from '../components/registration/Complete'

// Appointment Flow
import Appointments from '../components/Appointments'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/reg/name'
    },
    {
        path: '/reg',
        component: Registration,
        children: [
            {
                path: 'insurancePhotos',
                component: RegInsurancePhotos
            },
            {
                path: 'identificationPhotos',
                component: RegIdentificationPhotos
            },
            {
                path: 'name',
                component: RegName
            },
            {
                path: 'phone',
                component: RegPhone
            },
            {
                path: 'verifyPhone',
                component: RegVerifyPhone
            },
            {
                path: 'email',
                component: RegEmail
            },
            {
                path: 'dateOfBirth',
                component: RegDateOfBirth
            },
            {
                path: 'emergencyContact',
                component: RegEmergencyContact
            },
            {
                path: 'medicalRecordsRelease',
                component: RegMedicalRecordsRelease
            },
            {
                path: 'medicalRecordsReleaseStep1',
                component: RegMedicalRecordsReleaseStep1
            },
            {
                path: 'medicalRecordsReleaseStep2',
                component: RegMedicalRecordsReleaseStep2
            },
            {
                path: 'medicalRecordsReleaseStep3',
                component: RegMedicalRecordsReleaseStep3
            },
            {
                path: 'medicalRecordsReleaseStep4',
                component: RegMedicalRecordsReleaseStep4
            },
            {
                path: 'medicalRecordsReleaseStep5',
                component: RegMedicalRecordsReleaseStep5
            },
            {
                path: 'guardianContact',
                component: RegGuardianContact
            },
            {
                path: 'maritalStatus',
                component: RegMaritalStatus
            },
            {
                path: 'employer',
                component: RegEmployer
            },
            {
                path: 'homeAddress',
                component: RegHomeAddress
            },
            {
                path: 'gender',
                component: RegGender
            },
            {
                path: 'race',
                component: RegRace
            },
            {
                path: 'ethnicity',
                component: RegEthnicity
            },
            {
                path: 'emergency',
                component: RegEmergencyRoom
            },
            {
                path: 'background',
                component: RegBackground
            },
            {
                path: 'ssBackground',
                component: SsBackground
            },
            {
                path: 'bhBackground',
                component: BhBackground
            },
            {
                path: 'bhBackgroundA',
                component: BhBackgroundGad
            },
            {
                path: 'bhBackgroundB',
                component: BhBackgroundPhq
            },
            {
                path: 'bhBackgroundAssessment1',
                component: BhBackgroundSuicideSeverityStep1
            },
            {
                path: 'bhBackgroundAssessment2',
                component: BhBackgroundSuicideSeverityStep2
            },
            {
                path: 'conditions',
                component: RegConditions
            },
            {
                path: 'programs',
                component: RegPrograms
            },
            {
                path: 'colonCancerScreening',
                component: RegColonCancerScreening
            },
            {
                path: 'mammogram',
                component: RegMammogram
            },
            {
                path: 'trt',
                component: RegTrt
            },
            {
                path: 'hasInsurance',
                component: RegHasInsurance
            },
            {
                path: 'insurance',
                component: RegInsurance
            },
            {
                path: 'consentElectronicSignature',
                component: RegConsentElectronicSignature
            },
            {
                path: 'consentHipaa',
                component: RegConsentHipaa
            },
            {
                path: 'consentSpecimenCollection',
                component: RegConsentSpecimenCollection
            },
            {
                path: 'consentAssignmentOfBenefits',
                component: RegConsentAssignmentOfBenefits
            },
            {
                path: 'consentFinancialPolicy',
                component: RegConsentFinancialPolicy
            },
            {
                path: 'consentPatientTreatmentContract',
                component: RegConsentPatientTreatmentContract
            },
            {
                path: 'consentPrivacyPractices',
                component: RegConsentPrivacyPractices
            },
            {
                path: 'medicareNumber',
                component: RegMedicareNumber
            },
            {
                path: 'summary',
                component: RegSummary
            },
            {
                path: 'complete',
                component: RegComplete
            }
        ]
    },
    {
        path: '/a/:id',
        component: Appointments
    },
    {
        path: '/b/:db_id',
        component: Appointments
    }
]

const router = new VueRouter({
    routes
})

// localForage is async, app is paused till storage is loaded
const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
    next()
}
router.beforeEach(waitForStorageToBeReady)

export default router
